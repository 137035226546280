<template>
  <v-row>
    <v-col cols="0" md="12" sm="12" lg="12" xl="12" class="pt-0">
      <session-title class="pt-8 px-10 pb-5" title="Compliance" />
    </v-col>
    <v-col>
      <compliance-grid />
    </v-col>
  </v-row>
</template>

<script>
import SessionTitle from "@/components/SessionTitle";
import ComplianceGrid from "@/components/ComplianceGrid";

export default {
  name: "ComplianceExternal",
  components: {
    "session-title": SessionTitle,
    "compliance-grid": ComplianceGrid,
  },
};
</script>

<template>
  <v-container fluid>
    <v-row
      v-if="articles.length > 0"
      align="center"
      justify="start"
      class="px-12"
    >
      <v-col v-for="item in articles" :key="item.id" cols="12" md="3" sm="6">
        <v-hover v-slot="{ hover }" open-delay="20">
          <v-card
            height="500"
            :elevation="hover ? 16 : 0"
            color="indigo lighten-5"
            class="mx-auto d-flex flex-column justify-space-between"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              :src="item.featuredImage"
            />
            <v-card-subtitle class="text-uppercase pt-5 px-6 pb-0">
              {{ item.createdAt | dateFormatted }}
            </v-card-subtitle>
            <v-card-text class="py-0 px-2 text-card">
              <v-card-title style="word-break: break-word">
                {{ item.headline | stringLimit(50) }}
              </v-card-title>
              <v-card-subtitle>{{
                item.caption | stringLimit(80)
              }}</v-card-subtitle>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ml-5 mb-5"
                outlined
                color="secondary"
                route
                :to="`${
                  redirectToExternalPage() ? '/external' : ''
                }/read-compliance/${item.slug}`"
              >
                Ver mais
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center" class="px-12">
      <v-col cols="12" class="text-center">
        <h3 class="text--disabled">Não há nenhuma notícia por enquanto.</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { findActiveExternalCompliances } from "../services/compliance-service";
export default {
  name: "ComplianceGrid",
  props: {
    max: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    articles: [],
  }),
  async created() {
    this.articles = await findActiveExternalCompliances();
  },
  methods: {
    redirectToExternalPage() {
      const EXTERNAL_ROUTE_NAME = "external.compliance";

      const currentRoutName = this.$router?.history?.current?.name;

      const redirectToExternalComplianceRead =
        currentRoutName === EXTERNAL_ROUTE_NAME;

      return redirectToExternalComplianceRead;
    },
  },
};
</script>
